import React, { useState } from "react";

import style from "./Confirmation.module.css";
import { useNavigate } from "react-router-dom";
import loader from "../assets/loading.webp";
type Props = {};

export default function Confirmacao({}: Props) {
  const navigation = useNavigate();
  const [data, setData] = useState({
    name: "",
    email: "",
    confirm: "",
    phone: "",
    pessoas: 0,
  });

  const [enviado, setEnviado] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const requestConfirm = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    fetch("https://confirm-presence-api.vercel.app", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      setIsLoading(false);
      setEnviado(true);
    });
  };

  return (
    <div className={style.wrapper}>
      <form style={{ display: !enviado ? "block" : "none" }}>
        <h1>Confirme sua presença</h1>
        <input
          type='text'
          placeholder='Nome completo'
          name='name'
          value={data.name}
          onChange={(event) => setData({ ...data, name: event.target.value })}
        />
        <br />
        <legend>Você irá ao evento?</legend>
        <input
          type='radio'
          value='Sim'
          name='confirma'
          onChange={(event) =>
            setData({ ...data, confirm: event.target.value })
          }
        />{" "}
        Sim
        <input
          type='radio'
          value='Não'
          name='confirma'
          onChange={(event) =>
            setData({ ...data, confirm: event.target.value })
          }
        />{" "}
        Não
        <br />
        <legend>Email</legend>
        <input
          type='email'
          name='email'
          value={data.email}
          onChange={(event) => setData({ ...data, email: event.target.value })}
        />
        <br />
        <legend>Telefone</legend>
        <input
          type='phone'
          name='phone'
          value={data.phone}
          onChange={(event) => setData({ ...data, phone: event.target.value })}
        />
        <br />
        <legend>Quantas pessoas vão?</legend>
        <input
          type='number'
          name='pessoas'
          value={data.pessoas}
          onChange={(event) =>
            setData({ ...data, pessoas: parseInt(event.target.value) })
          }
        />
        <br />
        {!isLoading && (
          <button type='submit' onClick={requestConfirm}>
            Enviar
          </button>
        )}
        {isLoading && <img src={loader} width={"30px"} />}
      </form>

      <div
        className={style.confirmation}
        style={{ padding: "30px", display: enviado ? "block" : "none" }}
      >
        Obrigado por confirmar sua presença!
        <br />
        <div className={style.bt} onClick={() => navigation("/")}>
          Voltar
        </div>
      </div>
    </div>
  );
}
