import React from "react";

import leaves1 from "./assets/leaves01.png";
import leaves2 from "./assets/leaves02.png";
import we from "./assets/we.png";

import styles from "./App.module.css";
import { useNavigate } from "react-router-dom";

function App() {
  const navigation = useNavigate();
  return (
    <div className={`${styles.wrapper}`}>
      <div
        className={styles.leaves1}
        style={{
          backgroundImage: `url(${leaves1})`,
        }}
      />
      <div
        className={styles.leaves2}
        style={{
          backgroundImage: `url(${leaves2})`,
        }}
      />
      <div className={styles.saveDate}>SAVE THE DATE</div>

      <div className={`${styles.title}`}>Hugo & Rapha</div>
      <div style={{ fontSize: "24px", marginTop: "10px" }}>...vão se casar</div>

      <div className={styles.date}>
        <div>17</div>
        <div>.</div>
        <div>02</div>
        <div>.</div>
        <div>24</div>
      </div>

      <div className={styles.location}>
        <p>JUIZ DE FORA | MINAS GERAIS | 13:00</p>
        <p>R. Randal de Oliveira, 107 - Parque Guadalajara</p>
        <a
          target='_blank'
          href='https://www.google.com/maps/place/R.+Randal+de+Oliveira,+107+-+Parque+Guadalajara,+Juiz+de+Fora+-+MG,+36033-660,+Brasil/'
        >
          ABRIR NO MAPS
        </a>
        <p></p>
        <a
          target='_blank'
          href='https://calendar.google.com/calendar/event?action=TEMPLATE&amp;tmeid=MTQzZnY5YWE2a29uZGQzMXM2OTk3YWo0bzUgaHVnb3JlemVuZGVkZXZAbQ&amp;tmsrc=hugorezendedev%40gmail.com'
        >
          Salvar no Google Agenda
        </a>
      </div>

      <a
        href='#'
        className={styles.bt}
        onClick={() => navigation("/confirm")}
        style={{ color: "inherit", textDecoration: "none" }}
      >
        CONFIRMAR PRESENÇA
      </a>
    </div>
  );
}

export default App;
