import React from "react";

export const Fotos = () => {
  return (
    <iframe
      title='Widget Iframe'
      src='https://widgetviewer.photoconnector.net?widgetId=ae6a6a2d-b761-4ce5-8f3d-ea1fd7a87669&securityId=6f28b9a6-e616-452c-aabc-b74291c59e7c&locale=en-GB'
      frameBorder='0'
      allowFullScreen={true}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    ></iframe>
  );
};
